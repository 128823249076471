<template>
  <div class="info-wrap">
    <a-spin :spinning="loadding">
      <a-form
        class="form-box"
        layout="vertical"
        ref="formRef"
        :model="formData"
        :colon="false"
      >
        <div class="btns">
          <a-button v-show="!isEdit" @click="isEdit = true">{{ $t('BN_Comm_Edit') }}</a-button>
          <!-- 编辑 -->
          <a-button type="primary" v-show="isEdit" @click="submit"
            >{{ $t('CM_Confirm') }}</a-button
          >
          <!-- 确定 -->
          <a-button v-show="isEdit" @click="isEdit = false">{{ $t('CM_Cancel') }}</a-button>
          <!-- 取消 -->
        </div>
        <a-row :gutter="16">
          <a-col :span="12">
            <!-- 头像 -->
            <a-form-item :label="$t('CM_Header')" name="portrait">
              <div class="avatar-box">
                <div class="left">
                  <img :src="imageUrl" v-if="imageUrl" />
                  <img src="@/assets/image/teacher_avatar.png" v-else />
                </div>
                <div class="right" v-show="isEdit">
                  <div class="btn">
                    <a-button>{{ $t('upload.upload_img') }}</a-button>
                    <!-- 上传图片 -->
                    <input
                      class="up-input"
                      ref="uploadInput"
                      type="file"
                      accept="image/jpg, image/jpeg, image/png, image/gif"
                      @change="selectFile"
                    />
                  </div>
                  <p>
                    <InfoCircleOutlined
                      style="margin-right: 5px"
                    />{{ $t('teacher.avatar_tip') }}
                  </p>
                  <!-- 图片上传比例为3:4，最佳上传分辨率300*400 -->
                </div>
              </div>
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <!-- 讲师等级 -->
            <a-form-item
              :label="$t('teacher.lecturer_level')"
              name="gender"
              style="margin-top: 88px"
            >
              <a-input :value="formData.levelName" disabled>
                <template #prefix>
                  <img
                    class="prefix-icon"
                    :src="formData.levelPicture"
                    alt="icon"
                  />
                </template>
              </a-input>
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <!-- 姓名 -->
            <a-form-item :label="$t('teacher.name')" name="tel">
              <OpenData
                type="userName"
                :openid="formData.lecturerName"
                v-if="
                  (platformConfig.platform == 26 ||
                    platformConfig.platform == 31) &&
                  formData.lecturerType == 1
                "
              />
              <a-input v-else :value="formData.lecturerName" disabled />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <!-- 讲师分类 -->
            <a-form-item :label="$t('teacher.lecturer_classification')" name="tel">
              <a-input :value="formData.folderName" disabled />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <!-- 性别 -->
            <a-form-item :label="$t('teacher.sex')" name="gender">
              <a-input
                :value="
                  formData.gender == 1 ? $t('teacher.man_woman', 1) : formData.gender == 2 ? $t('teacher.man_woman', 2) : ''
                "
                disabled
              />
              <!-- 男 女 -->
            </a-form-item>
          </a-col>
          <a-col :span="12" v-if="!ISHTSY">
            <!-- 手机号 -->
            <a-form-item :label="$t('teacher.mobile')" name="mobile">
              <a-input :value="formData.mobile" disabled />
            </a-form-item>
          </a-col>
          <a-col :span="12" v-if="!ISHTSY">
            <!-- 邮箱 -->
            <a-form-item :label="$t('teacher.email')" name="email">
              <a-input :value="formData.email" disabled />
            </a-form-item>
          </a-col>
          <a-col :span="12" v-if="!ISHTSY">
            <!-- 座机号 -->
            <a-form-item :label="$t('teacher.tel')" name="tel">
              <a-input :value="formData.tel" disabled />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <!-- 职务 -->
            <a-form-item :label="$t('teacher.position')" name="positionRank">
              <a-input :value="formData.positionRank" disabled />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <!-- 出生日期 -->
            <a-form-item :label="$t('teacher.birthday')" name="birthday">
              <a-input :value="formData.birthday" disabled />
            </a-form-item>
          </a-col>
          <a-col :span="24">
            <!-- 讲师简介 -->
            <a-form-item :label="$t('teacher.lecturer_intro')" name="intro">
              <a-textarea
                :rows="4"
                v-model:value="formData.intro"
                :placeholder="$t('teacher.lecturer_intro_p')"
                :disabled="!isEdit"
              />
              <!-- 请输入讲师简介 -->
            </a-form-item>
          </a-col>
          <a-col :span="24">
            <!-- 座右铭 | 专业技术职务 -->
            <a-form-item
              :label="!ISHTSY ? $t('teacher.motto') : '专业技术职务'"
              name="motto"
            >
              <a-textarea
                :rows="4"
                v-model:value="formData.motto"
                :placeholder="!ISHTSY ? $t('teacher.motto_p') : '请输入专业技术职务'"
                :disabled="!isEdit"
              />
              <!-- '请输入座右铭' : '请输入专业技术职务' -->
            </a-form-item>
          </a-col>
          <a-col :span="24">
            <!-- 授课方向 -->
            <a-form-item :label="$t('teacher.teachingDirection')" name="teachingDirection">
              <a-textarea
                :rows="4"
                v-model:value="formData.teachingDirection"
                :placeholder="$t('teacher.teachingDirection_p')"
                :disabled="!isEdit"
              />
              <!-- 请输入授课方向 -->
            </a-form-item>
          </a-col>
          <a-col :span="24">
            <!-- '擅长领域' : '型号项目队伍职级' -->
            <a-form-item
              :label="!ISHTSY ? $t('teacher.expertAreas') : '型号项目队伍职级'"
              name="expertAreas"
            >
              <a-textarea
                :rows="4"
                v-model:value="formData.expertAreas"
                :placeholder="
                  !ISHTSY ? $t('teacher.expertAreas_p') : '请输入型号项目队伍职级'
                "
                :disabled="!isEdit"
              />
              <!-- '请输入擅长领域' : '请输入型号项目队伍职级' -->
            </a-form-item>
          </a-col>
          <a-col :span="24">
            <!-- 专家称号 -->
            <a-form-item :label="$t('teacher.expertTitle')" name="expertTitle">
              <a-tag
                v-for="tag in formData.expertTitleArr"
                :closable="isEdit"
                @close="handleClose(tag)"
                :key="tag"
              >
                {{ tag }}
              </a-tag>
              <a-input
                v-if="inputVisible"
                v-show="isEdit"
                ref="inputRef"
                type="text"
                size="small"
                :maxlength="20"
                :style="{ width: '78px' }"
                v-model:value="inputValue"
                @blur="handleInputConfirm"
                @keyup.enter="handleInputConfirm"
              />
              <a-tag
                v-else
                v-show="isEdit"
                @click="showInput"
                style="background: #fff; border-style: dashed"
              >
                <plus-outlined />
                {{ $t('teacher.add_title') }}
                <!-- 添加称号 -->
              </a-tag>
            </a-form-item>
          </a-col>
          <a-col :span="24">
            <!-- '研究领域' : '专业队伍职级' -->
            <a-form-item
              :label="!ISHTSY ? $t('teacher.researchAreas') : '专业队伍职级'"
              name="researchAreas"
            >
              <a-textarea
                :rows="4"
                v-model:value="formData.researchAreas"
                :placeholder="!ISHTSY ? $t('teacher.researchAreas_p') : '请输入专业队伍职级'"
                :disabled="!isEdit"
              />
              <!-- '请输入研究领域' : '请输入专业队伍职级' -->
            </a-form-item>
          </a-col>
          <a-col :span="24">
            <!-- '工作简历' : '工作履历' -->
            <a-form-item
              :label="!ISHTSY ? $t('teacher.workExperience') : '工作履历'"
              name="workExperience"
            >
              <a-textarea
                :rows="4"
                v-model:value="formData.workExperience"
                :placeholder="!ISHTSY ? $t('teacher.workExperience_p') : '请输入工作履历'"
                :disabled="!isEdit"
              />
              <!-- '请输入工作简历' : '请输入工作履历' -->
            </a-form-item>
          </a-col>
          <a-col :span="24">
            <!-- '其他' : '教育履历' -->
            <a-form-item :label="!ISHTSY ? $t('teacher.others') : '教育履历'" name="others">
              <a-textarea
                :rows="4"
                v-model:value="formData.others"
                :placeholder="!ISHTSY ? $t('teacher.others_p') : '请输入教育履历'"
                :disabled="!isEdit"
              />
              <!-- '请输入其他' : '请输入教育履历' -->
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </a-spin>
    <!-- 上传头像 -->
    <a-modal
      v-model:visible="showDialog"
      :title="$t('teacher.upload_avatar')"
      :maskClosable="false"
    >
      <VuePictureCropper
        :boxStyle="{
          width: '300px',
          height: '400px',
          backgroundColor: '#f8f8f8',
          margin: 'auto',
        }"
        :img="pic"
        :options="{
          viewMode: 1,
          dragMode: 'move',
          aspectRatio: 300 / 400,
          cropBoxResizable: false,
        }"
        :presetMode="{
          mode: 'fixedSize',
          width: 300,
          height: 400,
        }"
      />
      <template #footer>
        <a-button @click="showDialog = false">{{ $t('CM_Cancel') }}</a-button>
        <!-- 取消 -->
        <a-button type="primary" @click="getResult">{{ $t('teacher.crop') }}</a-button>
        <!-- 裁切 -->
      </template>
    </a-modal>
  </div>
</template>

<script>
import { useI18n } from 'vue-i18n';
import {
  getCurrentInstance,
  reactive,
  ref,
  toRefs,
  nextTick,
  computed,
} from "vue";
import { useStore } from "vuex";
import { getTeacher, editTeacher } from "@/api/teacher";
import VuePictureCropper, { cropper } from "vue-picture-cropper";
import request from "@/utils/request";
import { uploadUrl, handelSemicolonWrap } from "@/utils/tools";
import OpenData from "@/components/OpenData.vue";
export default {
  components: {
    VuePictureCropper,
    OpenData,
  },
  setup() {
    const { t: $t } = useI18n();
    const store = useStore();
    const ISHTSY = process.env.VUE_APP_CLIENT === "htsy";
    const { proxy } = getCurrentInstance();
    const state = reactive({
      platformConfig: computed(() => store.getters.platformConfig),
      companyInfo: computed(() => store.getters.companyInfo),
      isEdit: false,
      imageUrl: "",
      pic: "",
      showDialog: false,
      loadding: false,
      inputVisible: false,
      inputValue: "",
      formData: {
        levelPicture: "",
        levelName: "",
        lecturerName: "",
        folderName: "",
        portrait: "",
        gender: 1,
        mobile: "",
        email: "",
        tel: "",
        birthday: "",
        positionRank: "",
        intro: "",
        motto: "",
        teachingDirection: "",
        expertAreas: "",
        expertTitle: "",
        expertTitleArr: [],
        researchAreas: "",
        workExperience: "",
        others: "",
      },
    });

    getTeacher().then((res) => {
      let d = res.data;
      if (ISHTSY) {
        d.workExperience = handelSemicolonWrap(d.workExperience);
        d.others = handelSemicolonWrap(d.others);
      }
      state.imageUrl = d.portrait;
      Object.keys(state.formData).forEach((key) => {
        if (key == "birthday") {
          let b = d[key] ? d[key] + "" : "";
          state.formData[key] = b
            ? b.slice(0, 4) + "-" + b.slice(4, 6) + "-" + b.slice(6)
            : "";
        } else if (key == "expertTitleArr") {
          state.formData[key] = d.expertTitle ? d.expertTitle.split(",") : [];
        } else {
          state.formData[key] = d[key];
        }
      });
    });

    const uploadInput = ref(null);
    let blobURL = "";
    const selectFile = (e) => {
      blobURL = "";
      const target = e.target;
      const { files } = target;
      if (!files) return;
      const file = files[0];
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        state.pic = String(reader.result);
        state.showDialog = true;
        if (!uploadInput.value) return;
        uploadInput.value.value = "";
      };
    };

    const getResult = async () => {
      const blob = await cropper.getBlob();
      const file = await cropper.getFile();
      try {
        blobURL = URL.createObjectURL(blob);
      } catch (e) {
        blobURL = "";
      }
      state.showDialog = false;
      state.imageUrl = blobURL;
      let formData = new FormData();
      formData.append("file", blob, file.name);
      request
        .post(uploadUrl + "study/me/upload/avatar", formData)
        .then((res) => {
          state.formData.portrait = res.data;
        });
    };

    const inputRef = ref();
    const handleClose = (removedTag) => {
      state.formData.expertTitleArr = state.formData.expertTitleArr.filter(
        (tag) => tag !== removedTag
      );
    };

    const showInput = () => {
      state.inputVisible = true;
      nextTick(() => {
        inputRef.value.focus();
      });
    };

    const handleInputConfirm = () => {
      const inputValue = state.inputValue;
      let tags = state.formData.expertTitleArr;

      if (inputValue && tags.indexOf(inputValue) === -1) {
        tags = [...tags, inputValue];
      }
      state.formData.expertTitleArr = tags;
      Object.assign(state, {
        inputVisible: false,
        inputValue: "",
      });
    };

    const formRef = ref(null);
    const submit = () => {
      formRef.value.validate().then(() => {
        state.loadding = true;
        if (state.formData.expertTitleArr) {
          state.formData.expertTitle = state.formData.expertTitleArr.join(",");
        }
        console.log(state.formData);
        editTeacher(state.formData).then((res) => {
          if (res.ret == 0) {
            proxy.$message.success($t('CM_Success'));
            // 操作成功
            state.isEdit = false;
          }
          state.loadding = false;
        });
      });
    };

    return {
      ISHTSY,
      ...toRefs(state),
      uploadInput,
      selectFile,
      getResult,
      inputRef,
      handleClose,
      showInput,
      handleInputConfirm,
      formRef,
      submit,
    };
  },
};
</script>

<style lang="less" scoped>
.info-wrap {
  .mixinWrap(828px);
  .form-box {
    position: relative;
    .btns {
      position: absolute;
      top: 1px;
      right: 1px;
      z-index: 1;
      button {
        margin-left: 10px;
      }
    }
    .ant-form-item {
      margin-bottom: 16px;
      ::v-deep(.ant-form-item-label) {
        & > label {
          color: #999;
        }
      }
      .prefix-icon {
        width: 22px;
        height: 22px;
      }
    }
  }
}
.avatar-box {
  .mixinFlex(space-between; center);
  .left {
    width: 90px;
    height: 120px;
    border-radius: 6px;
    font-size: 0;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .right {
    width: calc(100% - 104px);
    .btn {
      width: 88px;
      height: 32px;
      position: relative;
      margin-bottom: 10px;
      .up-input {
        position: absolute;
        top: 0;
        left: 0;
        width: 88px;
        height: 32px;
        opacity: 0;
        background-color: transparent;
        color: transparent;
        cursor: pointer;
      }
    }
    p {
      color: #999999;
      font-size: 12px;
      margin: 0;
    }
  }
}
</style>
